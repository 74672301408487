import swal from "sweetalert2"
import Rails from "@rails/ujs"
import I18n from "i18n-js"

Rails.confirm = function (message, element) {
  //
  // if you want to customize buttons:
  //
  // const swalWithBootstrap = swal.mixin({
  //   customClass: {
  //       confirmButton: 'btn btn-success',
  //       cancelButton: 'btn btn-info'
  //     },
  //     buttonsStyling: false
  //   })
  // swalWithBootstrap.fire(
  //   {
  //     title: message,
  //     type: 'warning',
  //     showCancelButton: true,
  //     reverseButtons: true
  //   }
  // ).then((result) => {
  //   if (result.value) {
  //     element.removeAttribute('data-confirm')
  //     element.click()
  //   }
  // })
  swal.fire({
    title: message,
    icon: "warning",
    showCancelButton: true,
    reverseButtons: true
  }).then((result) => {
    if (result.value) {
      element.removeAttribute("data-confirm")
      element.click()
    }
  })
}

export function notImplementedAlert (evt) {
  evt.preventDefault()
  swal.fire({
    title: I18n.t("frontend.common.not_implemented"),
    icon: "info"
  })
}