/* eslint-disable camelcase */
export default function companies_index () { return new CompaniesIndex() }

import datatable_init from "./datatable_init"

class CompaniesIndex {
  constructor () {
    const companies_options = {
      processing: true,
      serverSide: true,
      lengthChange: true,
      ordering: true,
      autoWidth: true,
      scrollX: false,
      stateSave: true,
      order: [[0, "asc"]],
      rowId (data) { return data[data.length - 1].row_id }
    }

    const companies_columndefs = [
      {
        targets: [-1, -2, 5],
        orderable: false,
        searchable: false,
        className: "text-center",
      }
    ]
    // customize datatables
    new datatable_init("companies_table", companies_options, companies_columndefs)
  }
}
