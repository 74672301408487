import { Controller } from "stimulus"
const MarkdownIt = require("markdown-it")

export default class extends Controller {
  connect () {
    const rawTemplate = this.rawTemplate()
    if (rawTemplate) {
      const md = new MarkdownIt()
      document.getElementById("template-doc").innerHTML = md.render(rawTemplate)
    }
  }

  rawTemplate () {
    const rawTextarea = document.getElementById("hidden-template")
    if (rawTextarea) {
      return document.getElementById("hidden-template").value
    }
  }
}
