/* globals I18n */

import {
  Controller
} from "stimulus"

import {
  notImplementedAlert
} from "javascript/sweetalert.js"

import {
  toast as iziToast
} from "javascript/izitoast.js"

import "bootstrap/js/dist/tooltip"
import swal from "sweetalert2"

export default class extends Controller {
  connect () {
    if (["new", "create"].includes(this.action())) {
      if ($("input.is-invalid").length > 0) {
        $("input.is-invalid").first().focus()
      } else {
        this.takeFocus(".action_new_autofocus")
      }
    } else {
      if (["edit", "update"].includes(this.action())) {
        if ($("input.is-invalid").length > 0) {
          $("input.is-invalid").first().focus()
        } else {
          this.takeFocus(".action_edit_autofocus")
        }
      }
    }
  }

  controller (check) {
    if (check) {
      return ($("body").data("controller") === check)
    } else {
      return $("body").data("controller")
    }
  }

  action (check) {
    if (check) {
      return ($("body").data("action") === check)
    } else {
      return $("body").data("action")
    }
  }

  copyToClipboard (e) {
    const input = document.getElementById(e.target.getAttribute("data-target"))
    input.select()
    document.execCommand("copy")
    swal.fire({
      title: I18n.t("frontend.common.copied"),
      icon: "info"
    })
  }

  takeFocus (selector) {
    if ($(selector).length > 0) {
      $(selector)[0].focus()
      $(selector)[0].select()
    }
  }

  notImplemented (evt) {
    notImplementedAlert(evt)
  }

  toast (title, body, kind) {
    iziToast(title, body, kind)
  }

 lastActivities () {
   if (this.righ_panel_showing) {
     this.righ_panel_showing = false
     return
   }

   $.ajax({
     url: $("#last_activities_list").data("url"),
     dataType: "json",
     error: (_jqXHR, textStatus, errorThrown) => this.toast(`AJAX Error: ${textStatus}`, errorThrown || "Lost connection to server", "error"),
     success: (data, _textStatus, _jqXHR) => {
       var result = "<div>"

       if (data.activities.data.length === 0) {
         result += `
          <div class='list-group-item list-group-item-accent-danger list-group-item-divider'>
          ${data.activities.literals.no_actitiy}
          </div>
       `
       } else {
         data.activities.data.forEach(activity => {
          result += `
            <div class='list-group-item list-group-item-accent-${activity.event_class} list-group-item-divider'>
            <div class='d-block'>${activity.customer_name}</div>
            <small class='text-muted'>${activity.document_name}</small>
            <p><small class='text-muted float-right' data-toggle='tooltip' data-placement='top' title='${activity.when}'>
            <strong>${activity.event}</strong> ${activity.time_ago}</small></p></div>
          `
         })
       }
       result += "</div>"
       $("#last_activities_parent").html(result)
       $("[data-toggle=\"tooltip\"]").tooltip()
       this.righ_panel_showing = !this.righ_panel_showing
     }
   })
 }
}