/* eslint-disable camelcase */
/* globals common_controller */
export default function customers_index () {
  return new CustomersIndex()
}

import datatable_init from "./datatable_init"
import sanitizeStr from "./my_sanitizer"
import swal from "sweetalert2"
require("bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css")
require("bootstrap-switch/dist/js/bootstrap-switch.min.js")

class CustomersIndex {
  constructor () {
    const customers_options = {
      processing: true,
      serverSide: true,
      lengthChange: true,
      ordering: true,
      autoWidth: true,
      scrollX: false,
      stateSave: true,
      order: [
        [1, "desc"]
      ],
      // last item in data has info regarding to row. See app/datatables/customers_datatable.rb
      rowId (data) {
        return data[data.length - 1].row_id
      }, // row_id
      "rowCallback": (row, data) => $(row).addClass(data[data.length - 1].row_class) // row_class
    }

    const customers_columndefs = [{
        targets: 0, // expand subdet
        orderable: false,
        searchable: false,
        className: "expand_control text-center",
        defaultContent: ""
      },
      {
        targets: 3,
        orderable: false,
        className: "text-center"
      },
      {
        targets: [-1, -2], // action columns
        orderable: false,
        searchable: false,
        className: "text-center"
      }
    ]

    const self = this
    const table = new datatable_init("customers_table", customers_options, customers_columndefs)

    $("#user_selector").on("change", function () {
      self.load(table)
    })

    // Boostrap switch
    $("[data-toggle=\"switch\"]")
      .bootstrapSwitch()
      .on("switchChange.bootstrapSwitch", (e, data) => {
        self.load(table)
      })

    // check if must reload table every 10 seconds
    setInterval(() => {
        if (($(".icon-arrow-up").length == 0) && ($(".modal.show").length) == 0) {
          self.findLastActivity(self, table)
        }
      },
      10000
    )
    $("#customers_table tbody").on("click", "td.expand_control", function () {
      let arrow
      let tr = $(this).closest("tr")
      const row = table.row(tr)

      if (row.child.isShown()) {
        row.child.hide()
        $("#customers_table").addClass("table-hover")
        arrow = tr.find("td.expand_control > i")
        return arrow.removeClass("icon-arrow-up").addClass("icon-arrow-down")
      } else {
        let url
        row.child.remove()
        $("#customers_table").removeClass("table-hover")

        const row_data = row.data()
        if (row_data) {
          url = row_data[row_data.length - 1].customer_documents_path
        } else {
          url = $(tr).data("customer_documents_path")
        }
        return $.ajax({
          url,
          error (_jqXHR, textStatus, errorThrown) {
            swal.fire({
              title: "AJAX Error",
              text: `${textStatus} ${errorThrown}`,
              icon: "error",
              showCancelButton: true,
              reverseButtons: true,
            })
            return
          },
          success (data, _textStatus, _jqXHR) {
            if (data.documents.elements.length === 0) {
              return
            }
            row.child(self.build_documents(data.documents)).show()
            arrow = tr.find("td.expand_control > i")
            arrow.removeClass("icon-arrow-down").addClass("icon-arrow-up")
            $(".activity_expand_control").on("click", function () {
              arrow = $(this).find("i")
              if (arrow.hasClass("icon-arrow-down")) {
                tr = $(this).closest("tr")
                return $.ajax({
                  url: $(this).data("activities_url"),
                  error (_jqXHR, textStatus, errorThrown) {
                    swal.fire({
                      title: "AJAX Error",
                      text: `${textStatus} ${errorThrown}`,
                      icon: "error",
                      showCancelButton: true,
                      reverseButtons: true,
                    })
                    return
                  },
                  success (data, _textStatus, _jqXHR) {
                    arrow.removeClass("icon-arrow-down").addClass("icon-arrow-up")
                    self.build_document_activies(tr, data.activities)
                    return $("[data-toggle=\"tooltip\"]").tooltip()
                  }
                })
              } else {
                return arrow.removeClass("icon-arrow-up").addClass("icon-arrow-down")
              }
            })
            return $("[data-toggle=\"tooltip\"]").tooltip()
          }
        })
      }
    })
  }

  load (table) {
    const user = $("#user_selector").val()
    const filter = $("#filter_resolved_switch").bootstrapSwitch("state")
    const url = `${$("#customers_table").data("url")}?user=${user}&filter_resolved=${filter}`
    table.ajax.url(url).load()
  }

  reload (table) {
    table.ajax.reload(null, false)
  }

  findLastActivity (context, table) {
    fetch(`${$("#last_activities_list").data("url")}?limit=1`).then(response => {
      return response.json()
    }).then(data => {
      if (data.activities.data.length === 1) {
        const tmp = (window.lastActivity ? window.lastActivity : new Date)
        window.lastActivity = data.activities.data[0].timestamp
        if (window.lastActivity > tmp) {
          context.reload(table)
        }
      }
    }).catch(err => {
      common_controller.toast(
        "No hay conexión",
        "Se ha perdido la conexión con el servidor",
        "error"
      )
    })
  }

  // findLastActivity (context, table) {
  //   $.ajax({
  //     url: `${$("#last_activities_list").data("url")}?limit=1`,
  //     dataType: "json",
  //     error: () => {},
  //     success: (data, _textStatus, _jqXHR) => {
  //       if (data.activities.data.length === 1) {
  //         const tmp = (window.lastActivity ? window.lastActivity : new Date)
  //         window.lastActivity = data.activities.data[0].timestamp
  //         if (window.lastActivity > tmp) {
  //           context.reload(table)
  //         }
  //       }
  //     }
  //   })
  // }

  build_documents (data) {
    return `
      <div class="card mb-0">
        <table class="table w-100" id="customer_document_${data.customer_id}">
          <thead class="thead-dark">
            <tr class="table-active"><th/>
              ${data.headers.map(header => `
                <th class="capitalize-first-word">${header}</th>`).join("")}
            </tr>
          </thead>
          <tbody>
            ${data.elements.map(element => `
              <tr class="table-light" data-customer_document_id="${element.id}">
                <td class="activity_expand_control text-center"
                  data-toggle="collapse" data-target=".document_activities_${element.id}"
                  data-activities_url="${element.activities_url}">
                  <i class="icon-arrow-down">
                </td>
                <td>${element.document_name}</td>
                <td class="text-center">${element.status}</td>
                <td>${sanitizeStr(element.note)}</td>
                <td>${sanitizeStr(element.user_name)}</td>
                <td class="text-center">${element.last_activity_event}</td>
                <td class="text-center" data-toggle="tooltip" data-placement="bottom" title="${element.last_activity_when}">
                ${element.last_activity_time_ago}</td>
                <td class="text-center">${element.edit}</td>
              </tr>`).join("")}
          </tbody>
        </table>
      </div>
    `
  }

  build_document_activies (tr, data) {
    const result = `
      <div class="collapse document_activities_${$(tr).data("customer_document_id")} show">
        <table class="table">
          ${data.map(activity => `
            <tr>
              <td><strong>${activity.event}<strong></td>
              <td data-toggle="tooltip" data-placement="right" title="${activity.when}">${activity.time_ago}</td>
              </tr>
          `).join("")}
        </table>
      </div>
    `
    $(`.document_activities_${$(tr).data("customer_document_id")}`).remove()
    return $(tr).children("td").eq(1).append(result)
  }
}