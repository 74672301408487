/* globals I18n */

import { Controller } from "stimulus"
import validate from "validate.js"
import { isValid as isValidDni } from "better-dni"
import swal from "sweetalert2"

export default class extends Controller {
  static targets = ["form", "submit", "input", "download"]

  connect () {
    const self = this
    validate.validators.vat_number = function (value, options, _key, _attributes) {
      const subject = self.cleanValue(value)
      if ((self.isValidCif(subject) === false) && (isValidDni(subject) === false)) {
        return options.message
      }
    }
    validate.validators.spanish_dni = function (value,options, key, attributes) {
      if (isValidDni(self.cleanValue(value)) === false) {
        return options.message
      }
    }
    validate.validators.iban = function (value, options, key, attributes) {
      if (self.isIBAN(self.cleanValue(value)) === false) {
        return options.message
      }
    }
  }

  submitForm (event){
    event.preventDefault()

    // trim every text input
    const inputs = document.querySelectorAll("input[type=text]")
    for (const input of inputs) {
      input.value = input.value.trim()
    }

    var errors = validate(this.formTarget, this.constraints())

    if (errors == null) {
      swal.fire(
        {
          title: I18n.t ("frontend.common.confirmation"),
          text: I18n.t("frontend.customer.yes_to_sign"),
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
        }
      ).then((result) => {
        if (result.value) {
          this.formTarget.submit()
        }
      })
    } else {
      this.showErrors(errors || {})
    }
  }

  download () {
    event.preventDefault()

    swal.fire(
      {
        title: I18n.t("frontend.common.confirmation"),
        text: I18n.t("frontend.customer.yes_to_download"),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true
      }
    ).then((result) => {
      if (result.value) {
        this.downloadTarget.parentElement.submit()
      }
    })
  }

  showErrors (errors) {
    let first = null
    for (const input of this.inputTargets) {
      this.showErrorsForInput(input, errors[input.name])
      if (!first) {
        first = input
      }
    }
    if (first) {
      // first.scrollIntoViewIfNeeded({ behavior: 'smooth' })
      first.scrollIntoView(true)
      first.focus()
    }
  }

  constraints () {
    var constraints = {}
    for (const input of this.inputTargets) {
      constraints[input.name] = JSON.parse(input.getAttribute("data-validate"))
    }
    return constraints
  }

  showErrorsForInput (input, errors) {
    this.clearErrors(input)
    if (errors) {
      input.classList.add("is-invalid")
      this.insertErrorMessages(input, errors)
    } else {
      input.classList.remove("is-invalid")
      input.classList.add("is-valid")
    }
  }

  clearErrors (input) {
    if (document.getElementById(`error_${input.name}`) != null) {
      document.getElementById(`error_${input.name}`).remove()
    }
  }

  insertErrorMessages (input, errors) {
    var html = document.createElement("div")
    html.innerHTML = errors.join(" ")
    html.id = `error_${input.name}`
    html.classList.add("invalid-feedback")
    input.after(html)
  }

  cleanValue (value) {
    if (value) {
      return value.trim().toUpperCase()
    } else {
      return ""
    }
  }

  isValidCif (cif) {
    if (!cif || cif.length !== 9) {
      return false
    }

    var letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"]
    var digits = cif.substr(1, cif.length - 2)
    var letter = cif.substr(0, 1)
    var control = cif.substr(cif.length - 1)
    var sum = 0
    var i
    var digit

    if (!letter.match(/[A-Z]/)) {
      return false
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i])

      if (isNaN(digit)) {
        return false
      }

      if (i % 2 === 0) {
        digit *= 2
        if (digit > 9) {
          digit = parseInt(digit / 10) + (digit % 10)
        }

        sum += digit
      } else {
        sum += digit
      }
    }

    sum %= 10
    if (sum !== 0) {
      digit = 10 - sum
    } else {
      digit = sum
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control
    }

    return String(digit) === control || letters[digit] === control
  }

  isIBAN (iban) {
    const modulus = (aNumStr, aDiv) => {
      let tmp = ""
      let i, r
      for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i)
        r = tmp % aDiv
        tmp = r.toString()
      }
      return tmp / 1
    }

    if (!iban) {
      return false
    }

    //Move front 4 digits to the end
    const rearrange =
      iban.substring(4, iban.length) +
      iban.substring(0, 4)

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    const alphaMap = {}
    const number = []
    $.each(alphabet, function (index, value) {
      alphaMap[value] = index + 10
    })

    $.each(rearrange.split(""), function (index, value) {
      number[index] = alphaMap[value] || value
    })

    return modulus(number.join("").toString(), 97) === 1
  }
}
