import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    // editing
    $("#document_fields").on("cocoon:after-insert", function (e, insertedItem) {
      // on creation hide fieldname, is calculated
      $(insertedItem).find(".document_fields_fieldname").css("display", "none")
      // set focus on first input
      $(insertedItem).find("input").first().focus()
    })

    // showing
    $("#sortable_document_fields").sortable({
      update: function (e, ui) {
        $.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable("serialize")
        })
      }
    })
  }

  close_modal () {
    $(this.modal).modal("hide")
  }

  get modal () {
    return this.targets.find("modal")
  }
}
