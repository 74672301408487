export default function users_index () { return new UsersIndex() }

import datatable_init from "./datatable_init"

class UsersIndex {
  // customize datatables for users dataset
  constructor () {
    new datatable_init("users_table")
  }
}
