/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// require here all exported coffeescript modules
import customers_index from "./customers_index"
import companies_index from "./companies_index"
import documents_index from "./documents_index"
import users_index from "./users_index"
import swal from "sweetalert2"
import CommonController from "../controllers/common_controller"

class Page {
  controller (check) {
    if (check) {
      return $("body").data("controller") === check
    } else {
      return $("body").data("controller")
    }
  }

  action (check) {
    if (check) {
      return $("body").data("action") === check
    } else {
      return $("body").data("action")
    }
  }
}

class InitApp {
  constructor () {
    const page = new Page
    // main inits

    // Override the default confirm dialog by rails
    $.rails.allowAction = function (link) {
      if (!link.data("confirm")) {
        return true
      }
      $.rails.showConfirmationDialog(link)
      return false
    }

    // User click confirm button
    $.rails.confirmed = function (link) {
      link.data("confirm", null)
      return link.trigger("click.rails")
    }

    // enable tooltips everywhere
    $("[data-toggle=\"tooltip\"]").tooltip()

    // Display the confirmation dialog
    $.rails.showConfirmationDialog = function (link) {
      const message = link.data("confirm")
      return swal({
        title: message,
        type: "warning",
        showCancelButton: true,
        reverseButtons: true
      }).then(function (result) {
        if (result.value) {
          return $.rails.confirmed(link)
        }
      })
    }

    // auto fade dimissable alerts. Sets opacity: 0 and display: none
    window.setTimeout(
      () => $(".alert-dimissable").fadeTo(500, 0).slideUp(500),
      4000
    )

    // page specific js
    if (page.controller("customers") && page.action("index")) {
      customers_index()
    }
    if (page.controller("companies") && page.action("index")) {
      companies_index()
    }
    if (page.controller("documents") && page.action("index")) {
      documents_index()
    }
    if (page.controller("users") && page.action("index")) {
      users_index()
    }

    // client side validations callbacks
    window.ClientSideValidations.callbacks.element.after = function (element, eventData) {
      $(".validable_form_submit").prop("disabled", ($(".is-invalid").length > 0))
    }

  }
}

// event binding
$(document).on("turbolinks:load", () => {
  new InitApp()
  window.common_controller = new CommonController()
})

$(document).on("load", () => {
  window.common_controller = new CommonController()
})

// exit from modals with ESC
$(document).bind("keydown", function (evt) {
  evt = evt || window.event
  if (evt.keyCode === 27) {
    try {
      evt.preventDefault()
    } catch (x) {
      evt.returnValue = false
    }
    return $(".modal.fade.show").modal("hide")
  }
})