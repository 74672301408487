export default function datatable_init (selector, options, columndefs) { return new DataTableInit(selector, options, columndefs) }

import JQuery from "jquery"
window.$ = window.JQuery = JQuery
require("datatables.net-bs4")
require("datatables.net-responsive-bs4")
require("datatables.net-buttons-bs4")
require("datatables.net-select-bs4")

require("datatables.net-bs4/css/dataTables.bootstrap4.css")
require("datatables.net-responsive-bs4/css/responsive.bootstrap4.css")
require("datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css")
require("datatables.net-select-bs4/css/select.bootstrap4.css")

class DataTableInit {
  constructor (selector, options, columndefs) {
    const DEFAULT_OPTIONS = {
      processing: true,
      serverSide: true,
      lengthChange: true,
      ordering: true,
      autoWidth: true,
      scrollX: false,
      stateSave: true,
      order: [[0, "asc"]]
    }

    const DEFAULT_COLUMNDEFS = [
      {
        targets: [-1, -2], // last two columns
        orderable: false,
        searchable: false,
        className: "text-center"
      }
    ]

    if (options == null) { options = DEFAULT_OPTIONS }
    if (columndefs == null) { columndefs = DEFAULT_COLUMNDEFS }

    const self = this
    this.selector = selector
    this.table = $(`#${selector}`)
    const initoptions = Object.assign(
      {
        ajax: self.table.data("url"),
        columnDefs: columndefs,
        fnPreDrawCallback (e, settings) {
          // page length select has up/down and dropdown buttons
          return $("select.custom-select").removeClass("custom-select custom-select-sm")
        },
        fnDrawCallback (data) {
          if ($(this).data("readonly")) { $(".btn_edit, .btn_delete").addClass("disabled") }
          $(`#${self.selector} tbody tr`).removeClass("table-active")
          // if a row was selected, select and display
          for (const tr of Array.from($(`#${self.selector} tbody tr`))) {
            const candidate = $(tr).find("td:first >a:first")
            if ($(candidate).data("id") === self.datatable_api().state()[`${self.selector}_selected_id`]) {
              $(tr).addClass("table-active")
              top
              $("html").scrollTop($(tr).offset().top)
              break
            }
          }
          return $("[data-toggle=\"tooltip\"]").tooltip()
        },
        stateSaveCallback (settings, data) {
          return sessionStorage.setItem("DataTables_" + settings.sInstance, JSON.stringify(data))
        },
        stateLoadCallback (settings) {
          return JSON.parse(sessionStorage.getItem("DataTables_" + settings.sInstance))
        },
        language: {
          decimal: "",
          emptyTable: "No hay datos que mostrar",
          info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
          infoEmpty: "Sin registros que mostrar",
          infoFiltered: "(filtrados de un total de _MAX_)",
          infoPostFix: "",
          thousands: ",",
          lengthMenu: "Grupos de _MENU_ entradas",
          loadingRecords: "Cargando...",
          processing: "Procesando...",
          search: "Buscar:",
          zeroRecords: "No se encuentran registros",
          paginate: {
            first: "Primero",
            last: "Último",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": ordenación ascendente",
            sortDescending: ": ordenación descendente"
          }
        }
      },
      options
    )
    const datatable = self.table.dataTable(
      initoptions
    ).on("stateSaveParams.dt", (e, settings, data) => // save current selected row for restoring when back
      data[`${self.selector}_selected_id`] = self.get_selected_id())

    // dynamically add a clear filter button
    // $("##{@selector}_filter").append(
    //     "<button class='btn btn-default btn-sm' type='button'>" +
    //     "<i class='fa fa-times-circle'></i></button>"
    // ).on 'click', (e) ->
    //   $('input[type=search]').val('').focus()
    //   datatable.fnFilter('')
    // $('input[type=search]').css('width', '170px')

    // set active a row when clicked
    $(`#${this.selector} tbody`).on("click", "tr", function (e) {
      self.set_selected_id($(this).find("td:first >a:first").data("id"))
      $(`#${self.selector} tbody tr`).removeClass("table-active")
      $(this).addClass("table-active")
    })

    return datatable.DataTable()
  }

  set_selected_id (value) { window[`#${this.selector}_selected_id`] = value }

  get_selected_id () { return window[`#${this.selector}_selected_id`] }

  datatable_api () { return this.table.dataTable().api() }
}
