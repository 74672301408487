/* globals I18n */

import { Controller } from "stimulus"
import swal from "sweetalert2"

export default class extends Controller {

  checkTemplate () {
    const fieldNames = document.querySelectorAll(".field-name")
    const doc = {
      fields: this.uniq(Array.from(fieldNames).map(e => e.innerText)),
      missing: []
    }
    const template = {
      fields: this.uniq(this.rawTemplate().match(/\[\[(.+?)\]\]/g)),
      missing: []
    }

    if ((
          this.checkThis(doc, template, (e) => `[[${e}]]`) +
          this.checkThis(template, doc, (e) => e.slice(2,-2))
        ) === 0) {
      swal.fire(
        {
          icon: "success",
          text: I18n.t("frontend.document.fields.ok")
        }
      )
    } else {
      swal.fire(
        {
          title: I18n.t("frontend.document.fields.error"),
          icon: "error",
          text: this.buildMissing(doc.missing, template.missing)
        }
      )
    }
  }

  checkThis (source, target, formatter) {
    for (const e of source.fields) {
      if (!target.fields.includes(formatter(e))) {
        target.missing.push(e)
      }
    }
    return target.missing.length
  }

  buildMissing () {
    const lists = Array.from(arguments)
    return lists.reduce((result, list) => result.concat(list), []).join(",")
  }

  rawTemplate () {
    const rawTextarea = document.getElementById("hidden-template")
    if (rawTextarea) {
      return document.getElementById("hidden-template").value
    }
  }

  uniq (array) {
    return Array.from(new Set(array))
  }
}
