export default function documents_index () { return new DocumentsIndex() }

import datatable_init from "./datatable_init"

class DocumentsIndex {
  // customize datatables
  constructor () {
    new datatable_init("documents_table")
  }
}
